:root{
  font-size: 16px;
  --core-height: 160px;
}
@font-face {
  font-family: 'Kohinoor Bangla';
  src: url('./assets/fonts/KohinoorBangla-Regular.otf');
  font-weight: normal;
  font-size: normal;
}
body {
  margin: 0;
  font-family: 'Kohinoor Bangla',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
button:disabled {
  cursor: not-allowed;
}
.mx-auto {
  margin: 0 auto;
}
.w-full {
  width: 100%;
}
.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.gap-4 {
  gap: 1rem;
}
.w-4 {
  width: 16px;
}
.h-4 {
  width: 16px;
}
.dropdown-container {
  position: relative;
  display: inline-block;
}

.menu-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  color: #F9AA10;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  top: 30px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  width: 120px;
  z-index: 100;
}

.dropdown-item {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: black;
}

.dropdown-item:hover {
  background: #f0f0f0;
}
.show-small {
  display: flex;
}
.flex-big {
  display: none;
}
@media (min-width: 1280px) {
  .show-small {
    display: none;
  }
  .flex-big {
    display: flex;
  }
}
.min-h {
  min-height: calc(100vh - 208px);
}
/* book style */
/* .book-container {
  position: relative;
  border: 15px solid #8B4513;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}
.demoPage {
  background-color: #fff;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}
.page-flip .page-shadow {
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.3);
} */
/* ------- book style ------*/
.stf__wrapper.--landscape {
  touch-action: none;
  user-select: none;
  -webkit-user-drag: none;
}
.stf__parent {
  width: 350px;
  height: 570px;
  border-radius: 5px;
  position: relative;
  border: 10px solid #F9AA10;
  box-sizing: content-box !important;
  overflow: hidden;
}
@media (min-width: 800px) {
  .stf__parent {
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  }
}
@media (min-width: 420px) {
  .stf__parent {
    width: 400px;
  }
}
.demoPage {
  background-color: #fff;
  border: 2px solid #ddd;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}
/* 
.demoPage:nth-child(odd) {
  background-color: #f9f9f9;
}

.demoPage:nth-child(even) {
  background-color: #f1f1f1;
} */
 .novels-page-container {
  background-color: #252525;
  min-height: calc(100vh - 160px);
  display: flex;
  align-items: center;
  justify-content: center;
 }
 .novels-page-container .most-wanted {
  border-top: solid 0px transparent !important;
 }
 .novels-page-container .loader {
  width: 100%;
  background-color: transparent;
 }
 .demoPage img {
  width: 100%;
  height: 100%;
 }
 .page-input {
  width: 75px;
  height: 40px;
  border-radius: 6px;
  padding-inline-start: 35px;
 }
 .page-handler-container {
  display: flex;
  align-items: center;
  justify-content:  center;
  flex-wrap: wrap;
  gap: 5px;
 }
 .mx-auto {
  margin: 0 auto;
 }
 .w-full {
  width: 100%;
 }
 .flex {
  display: flex;
 }
 .items-center {
  align-items: center;
 }
 .justify-center {
  justify-content: center;
 }
 .my-4 {
  margin: 1rem 0;
 }
 .py-12 {
  padding: 3rem 0;
 }
 .block {
  display: block;
 }